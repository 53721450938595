import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Header = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Thank you</h1>
        <p className="text-base font-semibold lg:text-2xl">Thank you for your message, we will be in touch shortly.</p>
      </div>
    </section>
  )
}

const ContactUsPage = () => {
  return (
    <Layout>
      <Seo 
        title="Contact Us" 
        description="We love to hear from users, come say hello"
      />
      <Header/>
    </Layout>
  )
}

export default ContactUsPage
